




import Vue from "vue";

export default Vue.extend({
  name: "MarkdownParser",
  props: {
    file: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    }
  },
  data: function() {
    return {
      fileContent: ""
    }
  },
  created: function() {
    let projet = require("@/config/legals.json")    
    if (!projet.hasOwnProperty(this.name.toUpperCase())) {
      this.$router.push({ name: 'Erreur 404' })
    } 
    projet = projet[this.name.toUpperCase()]

    const showdown = require("showdown")
    const converter = new showdown.Converter()
    let data = require("../config/texts/" + this.file + ".md").default

    data = data.replace("{{URL}}", projet.websiteUrl)
    data = data.replace("{{SHOP}}", projet.shopUrl)
    data = data.replace("{{NAME}}", this.name)

    this.fileContent = converter.makeHtml(data)
  }
});
